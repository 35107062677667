<template>
  <slot></slot>
</template>

<script setup>
const props = defineProps({
  containerId: {
    type: String,
    required: false,
    default: undefined,
  },
  shopifyBuyBtn: {
    type: Object,
    requred: true,
    default: () => {},
  },
  buttonDestination: {
    type: String,
    requred: false,
    default: 'checkout',
  },
  btnText: {
    type: String,
    requred: true,
    default: '',
  },
  btnClass: {
    type: String,
    requred: true,
    default: '',
  },
});

onMounted(() => {
  const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }

  function loadScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }

  function ShopifyBuyInit() {
    const client = ShopifyBuy.buildClient({
      domain: props.shopifyBuyBtn.shopify_buybtn_domain,
      storefrontAccessToken: props.shopifyBuyBtn.storefront_access_token,
    });

    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('product', {
        id: props.shopifyBuyBtn.product_id,
        node:
          (props.containerId && document.getElementById(props.containerId)) ||
          document.getElementById(props.shopifyBuyBtn.product_component_id),
        options: {
          product: {
            iframe: false,
            buttonDestination: props.buttonDestination,
            contents: {
              img: false,
              title: false,
              variantTitle: false,
              price: false,
              options: false,
              quantity: false, // determines whether to show any quantity inputs at all
              quantityIncrement: false, // button to increase quantity
              quantityDecrement: false, // button to decrease quantity
              quantityInput: false, // input field to directly set quantity
              button: true,
              description: false,
            },
            text: {
              button: props.btnText,
            },
            classes: {
              button: props.btnClass,
            },
          },
        },
      });
    });
  }
});
</script>
